import React from 'react'
import CONFIG from '../config'

// @ts-ignore
import logo from '../lib/images/logo.png'

const DEFAULT_MESSAGE =
  'Something bad happened.  We deeply apologize for the interruption and hope to be back online as soon as possible. Our team of radiologists is still working so if you need cases read please email the patient name and history to reports@radimal.ai.'

export default function ErrorPage() {
  return (
    <div className="d-flex vh-100 vw-100 align-items-center justify-content-center flex-column">
      <img style={{ height: '30px', width: 'auto' }} src={logo} />

      <h1 className="text-xl mb-3 mt-2">Temporary Disruption</h1>

      <p style={{ maxWidth: '600px' }} className="text-m m-3">
        {CONFIG.ERROR_MESSAGE || DEFAULT_MESSAGE}
      </p>
    </div>
  )
}
